
.contenedor-images{
    display:flex;
    justify-content: center;
    text-align: center;

    
}

.images{
    width: 75%;
   /*  height: 30%; */
    border-radius: 20px;
    
}

/* box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%; */