.container-image{
    background-image: url( '../assets/img/testimonials/cemred2.jpeg');
    
     background-size: cover;
     background-position: center center;
     background-repeat: no-repeat;
     
     
  }

  .subtitulo-header{
   
    color: rgb(255, 255, 255);
    
} 
  